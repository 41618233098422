<template>
  <div>
    <SkipToContent />
    <header class="bg-gold-100">
      <div class="h-14 lg:h-[72px] grid items-center justify-center gap-x-12 xl:gap-x-20 lg:grid-cols-[1fr,500px,384px,1fr]">
        <BaseButton
          to="/ostoskori"
          look="blank"
          class="hidden xs:inline-grid hover:bg-gold-50 text-pine-950 size-12 lg:w-auto p-0 lg:px-3 absolute left-4 lg:left-auto lg:right-4 text-sm top-1 lg:top-3"
          aria-label="Palaa ostoskoriin"
          title="Palaa ostoskoriin"
        >
          <ArrowLeftIcon class="size-6 lg:size-5" /> <span class="hidden lg:inline">Palaa ostoskoriin</span>
        </BaseButton>

        <NuxtLink :to="localePath('/')" class="mx-auto lg:mx-0 block text-center w-fit lg:col-start-2"><img src="~assets/logo/logo.svg" alt="Tarvikeskus" class="h-6 dark:invert"></NuxtLink>
      </div>
    </header>
    <main id="main-content" style="min-height: calc(100vh - min(17vw, 233px))">
      <NuxtPage />
    </main>
    <SiteFooter />
    <ToastNotifications />
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'

const localePath = useLocalePath()

const { locale } = useI18n()
const config = useRuntimeConfig()
useSeoMeta({
  titleTemplate: '%s | Tarvike.com',
  title: 'Laatutuotteet arkeen ja vapaalle',
  ogTitle: 'Laatutuotteet arkeen ja vapaalle',
  ogSiteName: 'Tarvike.com',
  ogUrl: config.public.BASE_URL,
  robots: 'INDEX,FOLLOW',
  ogImage: `${config.public.BASE_URL}/images/og/tarvike-og-image.png`,
  twitterImage: `${config.public.BASE_URL}/images/og/tarvike-og-image.png`,
  description: 'Ajoneuvot maalle, tielle ja vesille, laitteita metsään ja puutarhaan, varusteet arkeen ja vapaalle, varaosat, tarvikkeet, huolto.',
  ogDescription: 'Ajoneuvot maalle, tielle ja vesille, laitteita metsään ja puutarhaan, varusteet arkeen ja vapaalle, varaosat, tarvikkeet, huolto.'
})
useHead({
  htmlAttrs: {
    lang: locale.value
  }
})
</script>
